import { Controller } from "@hotwired/stimulus"
import Http from "@/lib/Http"
const MAX_RECENT_VIEWS = 24

export default class extends Controller {
  static targets = ["frameTooltip", "option", "mainImage", "thumbnail", "slider", "framingLeft", "framingRight"]

  static values = {
    listingId: Number,
    matte: Boolean,
    stretch: Boolean,
    cartUrl: String,
    priceUrl: String,
    listingTitle: String,
    listingPrice: Number,
    framingAvailable: Boolean,
    artistName: String,
  }

  initialize() {
    // swipe and slide variables
    this.currentIndex = 0
    this.endX = 0
    this.startX = 0

    this.storeRecentViews()
  }

  connect() {
    this.previewImageIndex = this.mainImageTargets.findIndex((el) => el.dataset.type === "preview")

    if (this.framingAvailableValue) {
      this.frame = this.optionTargets.find((target) => target.checked)?.value || ""

      const framingOptions = document.getElementById("product-frame-options")
      const overflown = framingOptions.scrollWidth > framingOptions.clientWidth
      if (overflown) {
        this.framingRightTarget.classList.remove("hidden")
      }
    }

    if (this.sliderTarget) {
      this.sliderTarget.addEventListener("touchstart", this.handleTouchStart.bind(this), { passive: true })
      this.sliderTarget.addEventListener("touchmove", this.handleTouchMove.bind(this), { passive: true })
      this.sliderTarget.addEventListener("touchend", this.handleTouchEnd.bind(this), { passive: true })
    }

    this.updateThumbnailStyles(this.currentIndex)

    // listen for frame-change events from Vue.js components
    window.addEventListener("cart:frame-change", this.updateFrameFromSpa.bind(this))
  }

  disconnect() {
    window.removeEventListener("cart:frame-change", this.updateFrameFromSpa.bind(this))
    this.sliderTarget.removeEventListener("touchstart", this.handleTouchStart.bind(this))
    this.sliderTarget.removeEventListener("touchmove", this.handleTouchMove.bind(this))
    this.sliderTarget.removeEventListener("touchend", this.handleTouchEnd.bind(this))
  }

  handleTouchStart(event) {
    this.startX = event.touches[0].clientX
  }

  handleTouchMove(event) {
    this.endX = event.touches[0].clientX
  }

  handleTouchEnd() {
    const deltaX = this.endX - this.startX

    if (Math.abs(deltaX) > 50) {
      if (deltaX < 0) {
        // minimum swipe distance to be considered a swipe
        this.nextSlide()
      } else {
        this.prevSlide()
      }
    }
  }

  nextSlide() {
    if (this.currentIndex < this.thumbnailTargets.length - 1) {
      this.currentIndex++
      this.updateSliderPosition(this.currentIndex)
    }
  }

  prevSlide() {
    if (this.currentIndex > 0) {
      this.currentIndex--
      this.updateSliderPosition(this.currentIndex)
    }
  }

  updateSliderPosition(index) {
    const sliderWidth = document.querySelector(".slide-wrapper").offsetWidth
    const offset = -index * sliderWidth
    this.sliderTarget.style.transform = `translateX(${offset}px)`

    this.updateThumbnailStyles(index)
    this.updateFramePreview(this.frame) // clean frame preview when swipe to not "preview" type of images
  }

  updateCart(e) {
    e.preventDefault()
    this.sendGAevents()

    Http.post(this.cartUrlValue, {
      listing_id: this.listingIdValue,
      framing: this.frame === "unframed" ? "" : this.frame,
    }).then(() => {
      document.dispatchEvent(new Event("product:cart-change")) // communicate with Vue.js CounterBtn
    })
  }

  updateThumbnailStyles(sliderIndex) {
    const selectedThumbnail = this.thumbnailTargets[sliderIndex]
    const newImageUrl = selectedThumbnail.dataset.previewurl
    this.mainImageTarget.dataset.src = newImageUrl // set current thumbnail image url for legacy photoswipe gallery

    this.thumbnailTargets.forEach((thumbnail, thumbnailIndex) => {
      if (thumbnailIndex === sliderIndex) {
        thumbnail.classList.add("opacity-100")
        thumbnail.classList.remove("md:opacity-50")
      } else {
        thumbnail.classList.add("md:opacity-50")
        thumbnail.classList.remove("opacity-100")
      }
    })
  }

  changeMainImage(event) {
    const target = event.currentTarget
    this.currentIndex = parseInt(target.dataset.index, 10)
    this.mainImageTarget.dataset.index = target.dataset.index // set props for legacy photoswipe gallery
    this.mainImageTarget.dataset.url = target.dataset.url

    this.updateSliderPosition(this.currentIndex)
    this.updateFramePreview(this.frame)
  }

  selectFrame(e) {
    this.frame = e.target.value

    if (this.currentIndex !== this.previewImageIndex) {
      this.currentIndex = this.previewImageIndex
      this.updateSliderPosition(this.currentIndex)
    }

    this.updateFramePreview(e.target.value)

    this.fetchPrice()
    this.toggleIconsVisibility()
  }

  updateFrameFromSpa(e) {
    this.frame = e.detail
    this.updateFramePreview(e.detail)
  }

  fetchPrice() {
    Http.get(
      this.priceUrlValue,
      { params: { option: this.frame === "unframed" ? null : this.frame } },
      { headers: { Accept: "text/vnd.turbo-stream.html" } },
    )
  }

  updateFramePreview(framingOption) {
    const previewImage = this.mainImageTargets[this.previewImageIndex]
    const imageWrapper = document.querySelector(".main-image-wrapper")
    const isCanvas = imageWrapper.dataset.material === "canvas"
    const framePreview = previewImage.closest(".frame-wrapper")
    const sliderWrapper = document.querySelector(".product-image-canvas")
    const frameLabel = framingOption.split(".")[0]

    framePreview.classList.remove(...(framePreview.classList.toString().match(/\bis-\S+/g) || []))
    imageWrapper.classList.remove("py-10")
    previewImage.classList.remove("p-16")
    sliderWrapper.classList.remove("py-4")

    if (frameLabel && frameLabel !== "unframed") {
      framePreview.classList.add("is-framed", `is-${frameLabel}`)

      imageWrapper.classList.add("py-10")
      if (!isCanvas) {
        previewImage.classList.add("p-16") // canvas artworks should not have an offset between frame and the picture
      }

      sliderWrapper.classList.add("py-4")
    }
  }

  toggleIconsVisibility() {
    const icons = document.querySelectorAll("[data-frame]")

    icons.forEach((icon) => {
      const isSelectedFrame = this.frame.includes(icon.dataset.frame)
      const iconWrapper = icon.closest(".framing-icon-wrapper")
      const checkboxWrapper = icon.closest(".framing-checkbox-wrapper")
      icon.classList.toggle("invisible", !isSelectedFrame)
      icon.classList.toggle("vibisble", isSelectedFrame)

      iconWrapper.classList.toggle(`is-${icon.dataset.frame}`, isSelectedFrame)
      checkboxWrapper.classList.toggle("checked", isSelectedFrame)
    })
  }

  scrollToOption(e) {
    const scrollingDirection = e.currentTarget.dataset.direction
    const isRight = scrollingDirection === "right"
    const parent = document.getElementById("product-frame-options")
    parent.scrollLeft = isRight ? parent.clientWidth : -parent.clientWidth
    this.framingRightTarget.classList.toggle("hidden", isRight)
    this.framingLeftTarget.classList.toggle("hidden", !isRight)
  }

  toggleFrameTooltip() {
    this.frameTooltipTarget.classList.toggle("hidden")
  }

  fetchConversation() {
    const turboFrame = document.getElementById("product-conversation-modal")

    if (turboFrame && !turboFrame.src) {
      turboFrame.src = turboFrame.dataset.src
    }
  }

  storeRecentViews() {
    try {
      let recentViews = []

      if (localStorage.recentViewsId) {
        recentViews = JSON.parse(localStorage.recentViewsId)
          .filter((listingId) => listingId !== this.listingIdValue)
          .slice(0, MAX_RECENT_VIEWS)
      }

      recentViews.push(this.listingIdValue)

      localStorage.recentViewsId = JSON.stringify(recentViews)
    } catch (error) {
      console.error("Error storing recent views:", error)
    }
  }

  sendGAevents() {
    dataLayer.push({
      event: "addToCart",
      ecommerce: {
        add: {
          products: [
            {
              name: this.listingTitleValue,
              id: this.listingIdValue,
              price: parseInt(this.listingPriceValue),
              brand: this.artistNameValue,
              quantity: 1,
            },
          ],
        },
      },
    })
    // GA4
    dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
    dataLayer.push({
      event: "add_to_cart",
      ecommerce: {
        currency: "AUD",
        value: parseInt(this.listingPriceValue),
        items: [
          {
            item_id: this.listingIdValue,
            item_name: this.listingTitleValue,
            item_brand: this.artistNameValue,
            quantity: 1,
          },
        ],
      },
    })
  }
}
